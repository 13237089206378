import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, mergeProps as _mergeProps, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-498677ae"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "content"
};
const _hoisted_2 = {
  class: "content_left"
};
const _hoisted_3 = {
  class: "name"
};
const _hoisted_4 = {
  key: 0,
  class: "value"
};
const _hoisted_5 = {
  key: 1,
  class: "value"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AddressLabel = _resolveComponent("AddressLabel");
  const _component_el_row = _resolveComponent("el-row");
  const _component_Eldialog = _resolveComponent("Eldialog");
  return _openBlock(), _createBlock(_component_Eldialog, _mergeProps(_ctx.$attrs, {
    visible: _ctx.show,
    "onUpdate:visible": _cache[0] || (_cache[0] = $event => _ctx.show = $event),
    title: _ctx.title,
    width: "600px",
    onConfirmOk: _ctx.confirm
  }), {
    form: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, [_createVNode(_component_el_row, null, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.info, item => {
        return _openBlock(), _createElementBlock("p", {
          key: item.name,
          class: _normalizeClass(item.hasOwnProperty('width') ? 'w_100' : '')
        }, [_createElementVNode("span", _hoisted_3, _toDisplayString(item.name), 1), item.name != '报警地址：' ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.alarmData[item.key]), 1)) : _createCommentVNode("", true), item.name == '报警地址：' ? (_openBlock(), _createElementBlock("span", _hoisted_5, [_createVNode(_component_AddressLabel, {
          point: _ctx.alarmData[item.key]
        }, null, 8, ["point"])])) : _createCommentVNode("", true)], 2);
      }), 128))]),
      _: 1
    })])])])]),
    _: 1
  }, 16, ["visible", "title", "onConfirmOk"]);
}