import { defineComponent, toRefs, reactive, computed, watch, defineAsyncComponent } from "vue";
import Eldialog from "@/components/Eldialog/index.vue";
import { ElSwitch, ElRadioGroup, ElRadioButton, ElCol, ElRow } from "element-plus";
import { useMessage } from "@/hooks/web/useMessage";
export default defineComponent({
  name: "AlarmInfo",
  components: {
    Eldialog,
    ElSwitch,
    ElRadioGroup,
    ElRadioButton,
    ElCol,
    ElRow,
    TreeSelect: defineAsyncComponent(() => import("@/components/TreeSelect/TreeSelect.vue")),
    AddressLabel: defineAsyncComponent(() => import("@/components/AddressLabel/"))
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    alarmData: {
      type: Object
    }
  },
  emits: ["handleSuccess", "update:visible"],
  setup(props, {
    emit
  }) {
    const message = useMessage();
    const refData = reactive({
      // alarmType:['',''],
      title: "报警详情",
      info: [{
        name: "事件类型：",
        value: "",
        key: "alarmname"
      }, {
        name: "IMIE号：",
        value: "",
        key: "mdtid"
      }, {
        name: "设备名",
        value: "",
        key: "objectname"
      }, {
        name: "所属单位：",
        value: "",
        key: "holdname"
      }, {
        name: "SIM卡号：",
        value: "",
        key: "phone"
      }, {
        name: "处理状态：",
        value: "",
        key: "result"
      }, {
        name: "处理时间：",
        value: "",
        key: "handleTime"
      }, {
        name: "处理人：",
        value: "",
        key: "userName"
      }, {
        name: "开始报警时间：",
        value: "",
        key: "recvtime",
        width: 100
      }, {
        name: "结束报警时间：",
        value: "",
        key: "endtime",
        width: 100
      }, {
        name: "报警内容：",
        value: "",
        key: "othervalue",
        width: 100
      }, {
        name: "报警地址：",
        value: "",
        key: "point",
        width: 100
      }],
      dataInfo: {},
      type: 0
    });
    watch(() => props.visible, val => {});
    // 初始化表单
    const show = computed({
      get: () => props.visible,
      set: val => {
        emit("update:visible", val);
      }
    });
    // 确定按钮
    const confirm = () => {
      emit("update:visible", false);
    };
    return {
      ...toRefs(refData),
      show,
      confirm
    };
  }
});